import { Button } from "@mantine/core";
import { Link, useOutletContext } from "@remix-run/react";
import { OutletContext } from "~/root";

export default function AuthButton(props: { buttonText?: string }) {
  const { supabase, user } = useOutletContext<OutletContext>();
  const handleSignout = async () => {
    await supabase.auth.signOut();
    window.location.reload();
  };
  if (user) {
    return (
      <Button className="h-10" onClick={handleSignout} color="primary">
        Log Out
      </Button>
    );
  }
  return (
    <Button className="h-10" component={Link} to="/signin" color="primary">
      {props.buttonText || "Sign In"}
    </Button>
  );
}
