import { Button } from "@mantine/core";
import { twMerge } from "tailwind-merge";

export const ContactButton = (props: {
  buttonText?: string;
  hideOnMobile?: boolean;
}) => {
  return (
    <Button
      component="a"
      href="mailto:hello@podsie.com"
      target="_blank"
      color="gray"
      variant="outline"
      className={twMerge(
        "text-secondary-800 h-10",
        props.hideOnMobile ? "hidden xs:block" : "",
      )}
    >
      {props.buttonText || "Contact Us"}
    </Button>
  );
};
