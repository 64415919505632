import { Anchor, AppShell, Box, Group, Image } from "@mantine/core";
import { useMatches } from "@remix-run/react";
import { twMerge } from "tailwind-merge";
import { tw } from "~/utils/stylingLiterals/tw";
import AuthButton from "../Auth/AuthButton";
import { ContactButton } from "./ContactButton";

const HeaderAnchor = ({
  href,
  label,
  matchingPaths,
}: {
  href: string;
  label: string;
  matchingPaths: string[];
}) => (
  <Anchor
    href={href}
    className="flex items-center h-full px-4 text-white font-medium text-sm"
    underline={matchingPaths.includes(href) ? "always" : "hover"}
  >
    {label}
  </Anchor>
);

export type NavRoute = {
  href: string;
  label: string;
};

type HeaderProps = {
  navRoutes: NavRoute[];
  variant?: "default" | "landing";
};

const baseClassName = tw`h-[60px] px-4`;

const classNames = {
  default: tw`bg-secondary-700 text-white`,
  landing: tw`bg-white text-secondary-800 border-b-[1px] border-gray-200`,
};

export default function Header({
  navRoutes,
  variant = "default",
}: HeaderProps) {
  const matches = useMatches();
  const matchingPaths = matches.map((match) => match.pathname);

  return (
    <Box>
      <AppShell.Header className={twMerge(baseClassName, classNames[variant])}>
        <Group justify="space-between" align="center" h="100%">
          <Group>
            <Group>
              <a href="/">
                <Image
                  alt="Podsie logo"
                  src="/assets/podsie-full_logo-green.png"
                  className="max-h-9"
                />
              </a>
            </Group>
            <Group h="100%" gap={0}>
              {navRoutes.map((route) => (
                <HeaderAnchor
                  key={route.href}
                  href={route.href}
                  label={route.label}
                  matchingPaths={matchingPaths}
                />
              ))}
            </Group>
          </Group>

          <Group>
            <Group>
              {variant === "landing" && <ContactButton hideOnMobile />}
              <AuthButton />
            </Group>
          </Group>
        </Group>
      </AppShell.Header>
    </Box>
  );
}
